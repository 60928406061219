import { Box, Button, Dialog, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material';
import React, { useState } from 'react'
import { t } from "i18next";
import api from "../API/Fetch_data_Api";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { useSelector } from 'react-redux';

const Paypal = ({ amount, promoCode = "" }) => {

    const settings = useSelector(state => state?.Settings)?.settings?.payment_gateways_settings;
    const orderDetails = useSelector((state) => state.OrderCartDetails)?.orderDetails;
    const delivery_type = useSelector((state) => state.DeliveryAddress)?.deliveryType;
    const date = localStorage.getItem("selectedDate");
    const time = localStorage.getItem("selectedSlot");

    const [order_id, setOrderID] = useState(0);

    const [open, setOpen] = useState(false);

    // eslint-disable-next-line no-unused-vars
    const openLinkInNewTab = (url) => {
        const newTab = window.open(url, "_blank");
        newTab.focus();
    }


    const handleClose = () => {
        setOpen(false);
        api.add_transactions(order_id, "cancelled").then(result => {
            window.location.reload();
        });
    };

    const notes =  orderDetails.orderNote

    const handlePayout = () => {


        api
            .plaecOrder(
                "paypal",
                date,
                time,
                delivery_type === "Home" ? orderDetails?.selectedAddress?.id : "",
                notes,
                promoCode.length > 0 ? promoCode[0].promo_code : "").then(result => {
                    setOpen(true);
                    if (result.error === false || result.error === "false") {
                        setOrderID(result.data.order_id)
                        return;
                    } else {
                        if (typeof result.message === "object") {
                            Object.values(result.message).forEach(e => {
                           
                                toast.error(e)
                            })
                        } else {
                            toast.error(result.message)
                        }
                    }

                });
    }

    // eslint-disable-next-line no-unused-vars
    const initialOptions = {
        client_id: settings?.paypal_client_key,
        currency: "USD",
        intent: "capture",
    };

    return (
        <Box>
            <Button fullWidth sx={{ my: 3 }} variant="outlined" onClick={e => {
                handlePayout();
                // initializePayment(onSuccess, onClose)
            }}>
                {t("make_a_payment")}
            </Button>

            <Dialog open={open} maxWidth={"lg"}>
                <Box width={{ xs: "100%", md: 500 }} height={400}>
                    <DialogTitle display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
                        <Typography variant="h6" > Pay with Paypal </Typography>
                        <IconButton aria-label="handle-close" onClick={e => handleClose()}>
                            <FontAwesomeIcon icon={faTimes} />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <PayPalScriptProvider options={{ "client-id": settings?.paypal_client_key }}>
                            <PayPalButtons createOrder={(data, actions) => {
                                return actions.order.create({
                                    purchase_units: [
                                        {
                                            amount: {
                                                value: amount,
                                            },
                                        },
                                    ],
                                });
                            }}
                                style={{
                                    layout: 'vertical',
                                    tagline: false,
                                    color: 'gold',
                                    shape: 'rect',
                                }}
                                onCancel={(data) => {
                                    toast.error("Transaction has been  cancelled")
                                    api.add_transactions(order_id, "cancelled").then(result => {
                                        window.location.reload();
                                    });
                                }}
                                onApprove={(data, actions) => {
                                    api.add_transactions(order_id, "success").then(result => {
                                        localStorage.removeItem("cart");
                                        localStorage.removeItem("orderNote");
                                        localStorage.removeItem("CartDetails");
                                        localStorage.removeItem("cartItems");
                                        localStorage.removeItem("selectedSlot");
                                        localStorage.removeItem("selectedDate");
                                        localStorage.removeItem("selectedAddres");
                                    }).then(res => {
                                        window.location.reload();
                                    });
                                }}
                                onError={(err) => {
                                    toast.err(err);
                                    api.add_transactions(order_id, "cancelled").then(result => {
                                        window.location.reload();
                                    });
                                }}
                            />
                        </PayPalScriptProvider>
                    </DialogContent>
                </Box>
            </Dialog>
        </Box>
    )
}

export default Paypal