import {
  ArrowBackIosNewOutlined,
  BorderColorTwoTone,
} from "@mui/icons-material";
import {
  Backdrop,
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  Chip,
  Divider,
  Drawer,
  Grid,
  IconButton,
  MenuItem,
  Radio,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { t } from "i18next";
import { useTheme } from "@emotion/react";
import { MAP_API, handleClose, handleOpen } from "../../../config/config";
import api from "../../../API/Fetch_data_Api";
import { useDispatch, useSelector } from "react-redux";
import { setAddress, updateAddress } from "../../../redux/UserAddress";
import { setDeliveryAddress } from "../../../redux/DeliveryAddress";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import UpdateGoogleMapBox from "../../GoogleMap/UpdateGoogleAddress";

export const DrawerDynamicAddress = () => {
  const [checked, setchecked] = useState();

  const [addressSelected, setAddressSelected] = useState();

  const [deleteIndex, setDeleteIndex] = useState(null);

  const dispatch = useDispatch();

  const [openDrawer, setOpenDrawer] = useState(false);

  const [formOpen, setFormOpen] = useState(false);

  const [index, setIndex] = useState("");

  const userAddress = useSelector((state) => state.UserAddress).address;

  const filterAddress =
    userAddress && userAddress.filter((item) => item.id === index);

  const [loading, setLoading] = useState(false);

  const [locationName, setLocationName] = useState();

  const [latitude, setLatitudex] = useState(
    parseFloat(filterAddress[0]?.lattitude)
  );

  const [longitude, setLongitudex] = useState(
    parseFloat(filterAddress[0]?.longitude)
  );

  const [city, setCity] = useState(filterAddress[0]?.city_name);

  const [appartment, setAppartment] = useState(filterAddress[0]?.area);

  const [addressType, setAddressType] = useState();

  const [isDefault, setIsDefault] = useState(
    filterAddress[0]?.is_default === "1"
      ? true
      : false
  );

  // Check if filterAddress has elements before accessing properties
  const editMobileInitialValue =
    filterAddress && filterAddress.length > 0 ? filterAddress[0]?.mobile : "";

  const [editMobile, setEditMobile] = useState(editMobileInitialValue);

  const AddAddress = async () => {
    setLoading(true);

    await api
      .AddAddress(
        filterAddress[0]?.id,
        editMobile,
        locationName,
        0,
        city,
        latitude,
        longitude,
        appartment,
        addressType,
        91,
        "",
        "",
        "",
        isDefault,
        ""
      )
      .then((result) => {
        setLoading(false);
        if (result.message === "address not exist") {
          toast.error("Address not exist");
        }
        if (result.error === true) {
          for (var key of Object.keys(result.message)) {
            if (key === "address") toast.error("Address is required");
            if (key === "city_name") toast.error("City Name is required");
            if (key === "area") toast.error("Area/Appartment Name is required");
            if (key === "mobile") toast.error("Mobile Number is required");
            if (key === "type") toast.error("The type field is required");
          }
        } else {
          toast.success(result.message);

          const addressData = userAddress.map((item) => {
            if (item.id === filterAddress[0]?.id) {
              return {
                ...item,
                address: locationName, // Update the address or other properties here
                area: appartment, // Update the address or other properties here
                city_name: city, // Update the address or other properties here
                city_id: city, // Update the address or other properties here
                mobile: editMobile, // Update the address or other properties here
              };
            }
            return item;
          });
          dispatch(setAddress(addressData));
          back();
          handleClose(setOpenDrawer);
        }
      })
      .catch((error) => {
        toast.error(error.message);
      });
    // handleClose(openForm);
  };

  const Addressback = () => {
    handleClose(setFormOpen);
    handleOpen(setOpenDrawer);
  };

  const back = () => {
    handleClose(setOpenDrawer);
  };

  const handleMobileChang = (e) => {
    const inputValue = e.target.value;
    const numericValue = inputValue.replace(/\D/g, "");
    // Limit the input to a maximum of 16 characters
    if (numericValue.length <= 16 || numericValue === "") {
      setEditMobile(numericValue);
    }
  };

  const selectedAddress = useSelector((state) => state.OrderCartDetails)
    ?.orderDetails?.selectedAddress;

  const setDefaultAddress = (address) => {
    if (selectedAddress) {
      setchecked(selectedAddress.id);
    } else {
      address.forEach((add, index) => {
        if (add.is_default === "1") {
          setAddressSelected(add);
          setchecked(add.id);
          const selectedAddress = JSON.stringify(add);
          dispatch(setDeliveryAddress(selectedAddress));
        }
      });
    }
  };

  useEffect(() => {
    setDefaultAddress(userAddress);
  }, [userAddress]);

  const handleDelete = (index) => {
    setDeleteIndex(index);
  };

  const handleDeleteClose = () => {
    setDeleteIndex(null);
  };

  const handleSelect = (index, address) => {
    setAddressSelected(address);
    setchecked(address.id);

    // Call the onSelectAddress function to update the parent component
    // onSelectAddress(address);
    const selectedAddress = JSON.stringify(address);

    // localStorage.setItem("selectedAddres", selectedAddress);
    dispatch(setDeliveryAddress(selectedAddress));
  };

  // Use useEffect to watch for changes in addressSelected and then update localStorage
  useEffect(() => {
    if (addressSelected !== undefined) {
      const selectedAddress = JSON.stringify(addressSelected);
      // localStorage.setItem("selectedAddres", JSON.stringify(selectedAddress));
      dispatch(setDeliveryAddress(selectedAddress));
      localStorage.setItem("checked", checked);
    }
  }, [addressSelected, checked]);

  useEffect(() => {}, [filterAddress]);

  // to delete particular address
  const handleDeleteAddress = async (address_id) => {
    await api
      .DeleteAddress(address_id)
      .then(async (result) => {
        toast.success(result.message);

        await api
          .getAddress()
          .then((response) => {
            dispatch(setAddress(response.data));
          })
          .catch((error) => console.log("error", error));
      })
      .catch((error) => console.log("error", error));
    handleDeleteClose();
  };

  const theme = useTheme();

  const CompleteAddress = () => {
    handleClose(setOpenDrawer);
    handleOpen(setFormOpen);
  };

  const handleLocationSelect = (address) => {
    let latitude = address?.lat;
    let longitude = address?.lng;
    setLatitudex(latitude);
    setLongitudex(longitude);
    setAppartment(address?.areaName);
    // setLocationName(address?.formatted_address);
    setCity(address?.city);
    setEditMobile(filterAddress[0]?.mobile);
  };

  useEffect(() => {
    dispatch(updateAddress(filterAddress));
  }, [index, dispatch]);

  useEffect(()=>{
    setAddressType(filterAddress[0]?.type.toLowerCase())
    setLocationName(filterAddress[0]?.address)
  },[filterAddress])


  return (
    <Box
      sx={{
        gridTemplateColumns: "repeat(auto-fill, minmax(300px, 1fr))",
      }}
      width={"100%"}
      alignContent={"center"}
    >
      <Grid container width={"100%"}>
        {userAddress?.length > 0 ? (
          userAddress?.map((address, index) => (
            <Grid item xs key={index} width={"100%"} sm={12} md={6}>
              <Box
                key={index}
                display={"block"}
                sx={{
                  mx: 1,
                  my: 2,
                  maxWidth: "100%",
                  border: "1px solid gray",
                  borderRadius: "10px",
                  p: 1,
                }}
              >
                <Box width={"100%"} textAlign={"center"} alignItems={"center"}>
                  <Grid container width={"100%"}>
                    <Grid
                      item
                      xs
                      width={"100%"}
                      display={"flex"}
                      sm={12}
                      md={6}
                      alignItems={"center"}
                      flexWrap={"wrap"}
                    >
                      <Typography
                        gutterBottom
                        variant="p"
                        component="div"
                        display={"flex"}
                        alignItems={"center"}
                      >
                        <Radio
                          checked={address.id === checked}
                          onChange={() => {
                            handleSelect(index, address);
                          }}
                          value={address.id}
                          name="radio-buttons"
                          inputProps={{ "aria-label": "A" }}
                        />
                        {address.city_name}
                      </Typography>
                      <Chip
                        variant="outlined"
                        size="small"
                        color="primary"
                        sx={{
                          width: "auto",
                          ml: 1,
                          borderRadius: "5px",
                          borderColor: "gray",
                        }}
                        label={address.type}
                      />
                    </Grid>
                    <Grid
                      item
                      width={"100%"}
                      sm={12}
                      md={6}
                      display={"flex"}
                      alignItems={"center"}
                      sx={{ mb: { sm: 2, md: 0 }, float: "right" }}
                    >
                      <Box
                        width={"100%"}
                        display={"flex"}
                        alignItems={"center"}
                        justifyContent={"end"}
                      >
                        <IconButton
                          aria-label="edit"
                          size="small"
                          onClick={(e) => {
                            setOpenDrawer(!openDrawer);
                            setIndex(address.id);
                          }}
                          sx={{
                            backgroundColor: "green",
                            color: "white",
                            mr: 1,
                            borderRadius: 2,
                            "&:hover": {
                              backgroundColor: "green",
                            },
                          }}
                        >
                          <BorderColorTwoTone fontSize="small" />
                        </IconButton>

                        <IconButton
                          aria-label="delete"
                          size="small"
                          sx={{
                            backgroundColor: "red",
                            color: "white",
                            mr: 1,
                            borderRadius: 2,
                            "&:hover": {
                              backgroundColor: "red",
                            },
                          }}
                          onClick={() => handleDelete(index)}
                        >
                          <FontAwesomeIcon icon={faTrashCan} />
                        </IconButton>
                      </Box>

                      <Backdrop open={deleteIndex === index}>
                        <Box
                          sx={{ background: theme.palette.background.box }}
                          p={4}
                        >
                          <Typography>{t("delete_address")}</Typography>
                          <Button
                            variant="contained"
                            color="error"
                            onClick={() => handleDeleteAddress(address.id)}
                          >
                            {t("delete")}
                          </Button>
                          <Button onClick={handleDeleteClose}>
                            {t("close")}
                          </Button>
                        </Box>
                      </Backdrop>
                    </Grid>
                  </Grid>
                </Box>
                <Typography color="text.secondary" variant="body2" p={1}>
                  {address.address}
                </Typography>
                <Typography color="text.secondary" variant="body2" p={1}>
                  {address.mobile}
                </Typography>
              </Box>
            </Grid>
          ))
        ) : (
          <Box width={"100%"}>
            <Typography py={4} width={"100%"} textAlign={"center"}>
              {t("no_address")}
            </Typography>
          </Box>
        )}
      </Grid>
      {/* <Drawer
        open={openDrawer}
        anchor="right"
        sx={{
          display: { xs: "block", sm: "block" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: { md: 580, xs: "100%" },
          },
        }}
      >
        <Box>
          <Box mt={1} mb={1} display={"flex"} alignItems={"center"}>
            <IconButton onClick={back}>
              <ArrowBackIosNewOutlined />
            </IconButton>
            <h3>{t("update_location_map")}</h3>
          </Box>
          <Divider />
          <Box p={2}>
          <UpdateGoogleMapBox
                    apiKey={MAP_API}
                    onSelectLocation={handleLocationSelect} 
                    />
          </Box>

          <Box p={2}>
            <Button variant="contained" fullWidth onClick={CompleteAddress}>
              {t("complete_address_button")}
            </Button>
          </Box>
        </Box>
      </Drawer> */}

      <Drawer
        // open={formOpen}
        open={openDrawer}
        anchor="right"
        sx={{
          display: { xs: "block", sm: "block" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: { md: 580, xs: "100%" },
          },
        }}
      >
        <Box>

          <Box mt={1} mb={1} display={"flex"} alignItems={"center"}>
            <IconButton onClick={back}>
              <ArrowBackIosNewOutlined />
            </IconButton>
            <h3>{t("complete_address")}</h3>
          </Box>
          <Divider />
          <UpdateGoogleMapBox
            apiKey={MAP_API}
            onSelectLocation={handleLocationSelect}
          />
          <Box p={2}>
            <Box>
              <Typography>{t("house_flat")}</Typography>
              <TextField
                placeholder="Enter House no"
                fullWidth
                className="address-form"
                value={locationName}
                sx={{
                  mb: 2,
                  backgroundColor: theme.palette.background.input,
                }}
                onChange={(e) => setLocationName(e.target.value)}
              />
              <Typography>{t("appartment_road")}</Typography>
              <TextField
                id="apartment"
                placeholder="Enter Road Name"
                fullWidth
                value={appartment}
                sx={{
                  mb: 2,
                  backgroundColor: theme.palette.background.input,
                }}
                onChange={(e) => setAppartment(e.target.value)}
                className="address-form"
              />
              <Typography>{t("city")}</Typography>
              <TextField
                id="city"
                placeholder="Enter City Name"
                value={city}
                sx={{
                  mb: 2,
                  backgroundColor: theme.palette.background.input,
                }}
                onChange={(e) => setCity(e.target.value)}
                fullWidth
                className="address-form"
              />
              <Typography>{t("mobile")}</Typography>
              <TextField
                id="mobile"
                placeholder="Enter Mobile Number"
                fullWidth
                className="address-form"
                value={editMobile}
                required
                sx={{
                  mb: 2,
                  backgroundColor: theme.palette.background.input,
                }}
                onChange={handleMobileChang}
                inputProps={{
                  pattern: "[0-9]*", // Allow only numeric input
                  inputMode: "numeric", // Display numeric keyboard on mobile devices
                }}
              />
            </Box>
            <Box display={"flex"} gap={3}>
              <Box width={"100%"}>
                <TextField
                  select
                  label=""
                  id="location-type"
                  className="m-1-w-52"
                  sx={{ mb: 2 }}
                  fullWidth
                  placeholder="type"
                  value={addressType}
                  defaultValue="home"
                  onChange={(e) => setAddressType(e.target.value)}
                >
                  <MenuItem value="home" selected={true}>
                    {t("home")}
                  </MenuItem>
                  <MenuItem value="office">{t("office")}</MenuItem>
                  <MenuItem value="other">{t("other")}</MenuItem>
                </TextField>
                <Box alignItems={"center"} display={"flex"}>
                  <Checkbox
                    checked={isDefault}
                    onClick={(e) => setIsDefault(!isDefault)}
                  />
                  <Typography>{t("default_address")}</Typography>
                </Box>
              </Box>
            </Box>

            <Button
              variant="contained"
              fullWidth
              className="address-form"
              onClick={() => AddAddress()}
              disabled={loading === true ? true : false}
            >
              {loading === true ? (
                <FontAwesomeIcon icon={faSpinner} spin />
              ) : (
                ""
              )}
              {t("continue")}
            </Button>
          </Box>
        </Box>
      </Drawer>
    </Box>
  );
};
