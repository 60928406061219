import { Button } from '@mui/material';
import React, { useState } from 'react'
import { usePaystackPayment } from "react-paystack";
import { useSelector } from 'react-redux';
import { t } from "i18next";
import api from "../API/Fetch_data_Api";
import { toast } from "react-toastify";
// import { useTheme } from "@emotion/react";

const Paystack = ({ amount, promoCode }) => {

    const payment_gateway = useSelector(state => state.Settings)?.settings?.payment_gateways_settings;
    const user_details = useSelector(state => state.UserData)?.profile;
    const delivery_type = useSelector((state) => state.DeliveryAddress)?.deliveryType;


    const date = localStorage.getItem("selectedDate");
    const time = localStorage.getItem("selectedSlot");

    const address_id = localStorage.getItem("selectedAddres");
    const address = address_id !== "" ? JSON.parse(address_id) : "";

    let order_note = localStorage.getItem("orderNote");

    const [order_id, setOrderID] = useState(0);


    const email = (!user_details.email) ? user_details?.data.email : user_details.email  

    let config = {
        reference: (new Date()).getTime().toString(),
        currency: "GHS",
        amount: amount * 100, //Amount is in the country's lowest currency. E.g Kobo, so 20000 kobo = N200
        publicKey: payment_gateway.paystack_key,
    };

    if (email) config.email = email

    // you can call this function anything

    const initializePayment = usePaystackPayment(config);


    // console.log(email);

    const handlePayout = () => {
        if (!email) {
            toast.error("Please Update Your Email ID");
            return;
        }

        api
            .plaecOrder(
                "paystack",
                   date,
                    time,
                      delivery_type === "Home" ? address?.id : "",
                       order_note,
                        promoCode.length > 0 ? promoCode[0].promo_code : "").then(result => {
         
                if (result.error === false) {
                    setOrderID(result.data.order_id);

                    const onSuccess = (reference) => {
                        // Implementation for whatever you want to do with reference and after success call.
             
                        api.add_transactions(result.data.order_id, "success").then(result => {
                            if (result.error === true) {
                                toast.error(result.message);
                            } else {
                                toast.success("Service Booked successfully");
                            }
                        })
                            .then(res => {
                                localStorage.removeItem("cart");
                                localStorage.removeItem("orderNote");
                                localStorage.removeItem("CartDetails");
                                localStorage.removeItem("cartItems");
                                localStorage.removeItem("selectedSlot");
                                localStorage.removeItem("selectedDate");
                                localStorage.removeItem("selectedAddres");
                            })
                            .then(res => {
                                window.location.reload();
                            });
                    };

                    // you can call this function anything
                    const onClose = () => {
                        // implementation for  whatever you want to do when the Paystack dialog closed.
               
                        api.add_transactions(order_id, 'cancelled').then((res) => {
                        });
                    }

                    initializePayment(onSuccess, onClose)


                } else {
                    toast.error(result.message);
                }
            });

    }



    return (
        <Button fullWidth sx={{ my: 3 }} onClick={() => {
            handlePayout();
            // initializePayment(onSuccess, onClose)
        }}>
            {t("make_a_payment")}
        </Button>
    )
}

export default Paystack