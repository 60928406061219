/* eslint eqeqeq: 0 */

import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import {
  Box,
  Breadcrumbs,
  Button,
  Card,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Skeleton,
  TextField,
  CardMedia,
} from "@mui/material";
import { useParams, Link } from "react-router-dom";
import { t } from "i18next";
import Layout from "../../layout/Layout";
import { LineSkeleton, PartnerSkeleton } from "./Skeletons";
import Partner from "./Partner";
import api from "../../../API/Fetch_data_Api";
import { PersonSearch } from "@mui/icons-material";
import { useTheme } from "@emotion/react";
import noDataImage from "../../../Images/No__data-pana.png";

// provider page and we got particular provider from reusable partner Section
export default function Provider() {
  const [provider, setProvider] = useState([]);
  const [category, setCategory] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(0);
  const [selectedOrder, setSelectedOrder] = useState("asc");
  const [searchPartner, setSearchPartner] = useState(null);
  const [searchCount, setSearchCount] = useState(0);

  const searchList = [
    {
      value: "asc",
      label: "Ascending",
    },
    {
      value: "desc",
      label: "Descending",
    },
  ];

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const responseProviders = await api.get_providers();
        setProvider(responseProviders.data);
        setSearchCount(responseProviders.total);
        setIsLoading(true);
      } catch (error) {
        setError(true);
        console.error("Error fetching providers:", error);
      }

      try {
        const responseCategories = await api.get_category();
        const categories = responseCategories.data.map((e) => ({
          name: e.name,
          id: e.id,
        }));
        setCategory(categories);
      } catch (error) {
        //setError(false);
        console.error("Error fetching categories:", error);
      }
    };

    fetchData();
  }, []);

  const retryFetchData = () => {
    setError(false); // Reset error state
    setIsLoading(true); // Set loading state
    api
      .get_providers()
      .then((response) => {
        setProvider(response.data);
        setSearchCount(response.total);
        setIsLoading(false);
      })
      .catch((error) => {
        setError(true); // Set error state
        setIsLoading(false); // Stop loading state
        console.error("Error fetching data:", error);
      });
  };

  const handleCategoryChange = (e) => {
    setSelectedCategory(e.target.value);
  };

  const handleOrderChange = (e) => {
    setSelectedOrder(e.target.value);
  };
  const handleSearch = (e) => {
    api
      .get_providers("", searchPartner, selectedCategory, selectedOrder)
      .then((response) => {
        setProvider(response.data);
        setSearchCount(response.total);
      })
      .catch((error) => {
        // If any promise rejects, set error state to true
        setError(true);
        console.error("Error fetching data:", error);
      });
  };

  return (
    <Box>
      {!error ? (
        <Box display={"flex"} gridColumn={3} flexWrap={"wrap"} m={0}>
          {!error ? (
            <Box sx={{ width: "100%" }}>
              {isLoading ? (
                <Box
                  display={"flex"}
                  sx={{
                    flexDirection: { xs: "column", md: "row" },
                    gap: { xs: 2, md: 1 },
                  }}
                  width={"100%"} // Ensure the container takes full width
                  mb={3}
                  alignContent={"center"}
                >
                  <Box width={{ xs: "100%", md: "15%" }}>
                    {" "}
                    {/* Adjust the width */}
                    <Typography variant="h6">{t("search_filter")}</Typography>
                  </Box>

                  <Box width={{ xs: "100%", md: "46%" }}>
                    {" "}
                    {/* Adjust the width */}
                    <TextField
                      id="outlined-basic"
                      onChange={(e) => setSearchPartner(e.target.value)}
                      fullWidth
                      size="small"
                      label="Search Provider"
                      placeholder="Search Provider"
                      variant="outlined"
                    />
                  </Box>

                  <Box width={{ xs: "100%", md: "25%" }}>
                    {" "}
                    {/* Adjust the width */}
                    <FormControl sx={{ width: "100%" }}>
                      <InputLabel id="demo-multiple-name-label">
                        Select Category
                      </InputLabel>
                      <Select
                        labelId="demo-multiple-name-label"
                        id="demo-multiple-name"
                        size="small"
                        onChange={handleCategoryChange}
                        defaultValue={selectedCategory}
                        input={<OutlinedInput label="Select Category" />}
                        MenuProps={MenuProps}
                      >
                        <MenuItem key={0} value={0}>
                          Select Category
                        </MenuItem>
                        {category.map((opt) => (
                          <MenuItem key={opt.id} value={opt.id}>
                            {opt.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>

                  <Box width={{ xs: "100%", md: "25%" }}>
                    {" "}
                    {/* Adjust the width */}
                    <FormControl sx={{ width: "100%" }}>
                      <InputLabel id="demo-multiple-name-label">
                        Sort By
                      </InputLabel>
                      <Select
                        labelId="demo-multiple-name-label"
                        id="demo-multiple-name"
                        defaultValue={selectedOrder}
                        size="small"
                        onChange={handleOrderChange}
                        input={<OutlinedInput label="Sort By" />}
                        MenuProps={MenuProps}
                      >
                        {searchList.map((opt) => (
                          <MenuItem key={opt.value} value={opt.value}>
                            {opt.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>

                  <Box>
                    {" "}
                    {/* Adjust the width */}
                    <Button
                      startIcon={<PersonSearch />}
                      variant="contained"
                      onClick={handleSearch}
                    >
                      {t("search")}
                    </Button>
                  </Box>
                </Box>
              ) : (
                <LineSkeleton />
              )}
            </Box>
          ) : (
            <></>
          )}

          {isLoading ? (
            <Box
              display={"flex"}
              sx={{
                justifyContent: { xs: "space-evenly", md: "space-between" },
              }}
              maxWidth={"100%"}
              width={"100%"}
            >
              <Grid
                container
                maxWidth={"100%"}
                spacing={3}
                sx={{ alignItems: "center", justifyItems: "center" }}
              >
                {provider.length > 0 ? (
                  provider.map((response) => {
                    return (
                      <Grid
                        // maxWidth={"380px"}
                        key={response.id}
                        width={"100%"}
                        item
                        xs={12}
                        lg={3}
                        md={searchCount === 1 ? 12 : searchCount == 2 ? 6 : 4}
                      >
                        <Partner
                          partner={response}
                          sx={{
                            height: "100%",
                            display: "block",
                            boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)", // Add box shadow CSS here
                            borderRadius: "10px",
                          }}
                        />
                      </Grid>
                    );
                  })
                ) : (
                  <Grid width={"100%"} item xs={12}>
                    {error ? (
                      <Box
                        display={"flex"}
                        flexDirection={"column"}
                        alignItems={"center"}
                        justifyContent={"center"}
                      >
                        <img
                          src={"/images/no-provider.png"}
                          alt="There is no providers"
                        />

                        <Typography variant="body1">
                          {t("no_provider")}
                        </Typography>
                      </Box>
                    ) : (
                      <Grid container spacing={2}>
                        <Grid
                          item
                          lg={12}
                          display={"flex"}
                          flexWrap={"wrap"}
                          justifyContent={"space-evenly"}
                          gap={2}
                        >
                          <>
                            <PartnerSkeleton />
                            <PartnerSkeleton />
                            <PartnerSkeleton />

                            <PartnerSkeleton />
                            <PartnerSkeleton />
                            <PartnerSkeleton />

                            <PartnerSkeleton />
                            <PartnerSkeleton />
                            <PartnerSkeleton />
                          </>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                )}
              </Grid>
            </Box>
          ) : (
            <Grid container spacing={2} mb={13}>
              <Grid
                item
                lg={12}
                display={"flex"}
                flexWrap={"wrap"}
                justifyContent={"space-evenly"}
                gap={2}
              >
                <>
                  <PartnerSkeleton />
                  <PartnerSkeleton />
                  <PartnerSkeleton />

                  <PartnerSkeleton />
                  <PartnerSkeleton />
                  <PartnerSkeleton />

                  <PartnerSkeleton />
                  <PartnerSkeleton />
                  <PartnerSkeleton />
                </>
              </Grid>
            </Grid>
          )}
        </Box>
      ) : (
        <Grid
          container
          spacing={2}
          direction="column" // Stack components vertically
          alignItems="center"
          justifyContent="center"
          height="100vh" // Adjust this according to your layout
          sx={{
            marginTop: {
              xs: 0, // No margin on extra small screens
              md: -12.5, // 100px margin on medium screens and larger
            },
          }}
        >
          <Grid item>
            <Card
              sx={{
                boxShadow: "none !important",
                // marginTop: {
                //   xs: 0, // No margin on extra small screens
                //   md: -30, // 100px margin on medium screens and larger
                // },
              }}
            >
              <CardMedia
                component="img"
                src={noDataImage} // Use the imported PNG image
                alt="No Data Image"
                sx={{
                  width: { xs: 300, sm: 600, md: 700 }, // Set width based on screen size
                  height: "auto", // Maintain aspect ratio
                  border: "none", // Remove border
                  boxShadow: "none", // Remove box shadow
                }}
              />
            </Card>
          </Grid>
          <Grid item>
            <Typography
              sx={{
                textAlign: "center", // Center the text
                marginTop: {
                  xs: 0, // No margin on extra small screens
                  md: -22, // 100px margin on medium screens and larger
                },
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  textAlign: "left",
                  fontFamily: "Plus Jakarta Sans",
                  fontWeight: "bold",
                  fontSize: "32px",
                  lineHeight: "32px",
                  letterSpacing: "0px",
                }}
              >
                {t("something_went_wrong")}
              </Typography>

              <Typography
                variant="body1"
                sx={{
                  color: "var(--secondary-color-343f53)", // Using custom color variable
                  textAlign: "left",
                  fontFamily: "Plus Jakarta Sans",
                  fontWeight: "normal",
                  fontSize: "20px",
                  lineHeight: "32px",
                  letterSpacing: "0px",
                  opacity: 0.7,
                  marginLeft: 5,
                }}
              >
                {t("try_again_later")}
              </Typography>
            </Typography>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              onClick={retryFetchData}
              sx={{
                textTransform: "none",
                marginTop: {
                  xs: 0, // No margin on extra small screens
                  md: -28, // 100px margin on medium screens and larger
                },
              }}
            >
              {t("retry")}
            </Button>
          </Grid>
        </Grid>
      )}
    </Box>
  );
}

export const MySkeleton = () => {
  return (
    <Skeleton
      height={500}
      sx={{ width: { sx: 200, md: 345 } }}
      variant="rectangular"
    ></Skeleton>
  );
};

export const SpecificProvider = () => {
  const [provider, setProvider] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const param = useParams();
  const { id } = param;

  const get_provider = () => {
    api
      .get_providers(0, "", id)
      .then((response) => {
        setProvider(response.data);
      })
      .then((response) => setIsLoading(true))
      .catch((error) => console.log("error", error));
  };

  const { name } = param;
  const formattedName = name
    .replace(/-/g, " ")
    .replace(/\b\w/g, (match) => match.toUpperCase());

  const company_name = localStorage.getItem("Company");
  document.title = `${formattedName} | ${company_name}`;

  useEffect(() => {
    get_provider();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const theme = useTheme();
  return (
    <Layout>
      <Box
        // bgcolor={theme.palette.background.heading}
        paddingTop={"40px"}
        paddingBottom={"44px"}
        mt={2}
      >
        <Container maxWidth="lg" className="mainContainer">
          <Breadcrumbs
            separator="|"
            aria-label="breadcrumb"
            className="mt-1 mb-1 breadcrumb"
          >
            <Box
              component={Link}
              to={"/"}
              className="breadrumb"
              sx={{
                color: theme.palette.color.breadcrum,
                textDecoration: "none",
              }}
              underline="none"
            >
             <strong> {t("home")}</strong>
            </Box>

            <Box
              component={Link}
              to={"/categories"}
              className="breadrumb"
              sx={{
                textDecoration: "none",
                color: theme.palette.color.breadcrum,
              }}
              underline="none"
            >
              <strong>{t("sub_categories")}</strong>
            </Box>

            <Typography color="text.primary">{formattedName}</Typography>
          </Breadcrumbs>
          <Typography variant="h4" gutterBottom>
            <strong>{formattedName} Providers</strong>
          </Typography>
        </Container>
      </Box>
      <Container className="mainContainer">
        <Box
          display={"flex"}
          gridColumn={3}
          flexWrap={"wrap"}
          gap={"22px"}
          justifyContent={"space-around"}
          p={4}
          sx={{
            background: theme.palette.background.box,
            mb: '30px'
          }}
        >
          {isLoading ? (
            <>
              {provider.length > 0 ? (
                provider.map((response, index) => {
                  return (
                    <>
                    <Box key={index}>
                      <Partner key={index} partner={response} />
                    </Box>
                    </>
                  );
                })
              ) : (
                <>
                  <Box
                    display={"flex"}
                    flexDirection={"column"}
                    alignItems={"center"}
                    justifyContent={"center"}
                  >
                    <img
                      src={"/images/no-provider.png"}
                      alt="There is no providers"
                      width={"100%"}
                      height={"100%"}
                    />

                    <Typography variant="body1">{t("no_provider")}</Typography>
                  </Box>
                </>
              )}
            </>
          ) : (
            <Grid container spacing={2}>
              <Grid
                item
                lg={12}
                display={"flex"}
                flexWrap={"wrap"}
                justifyContent={"space-evenly"}
                gap={2}
                mt={5}
              >
                <MySkeleton />
              </Grid>
            </Grid>
          )}
        </Box>
      </Container>
    </Layout>
  );
};
