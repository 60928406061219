import { useEffect, Suspense } from "react";
import { setBookmark } from "./redux/Bookmark";
import { setAddress } from "./redux/UserAddress";
import { updateCartItems, updateBaseCart } from "./redux/cart";
import { setSettings } from "./redux/Settings";
import api from "./API/Fetch_data_Api";
import { useDispatch, useSelector } from "react-redux";
import ScrollToTop from "./ScrollToTop";


import Router from "./router/Router";
import Loader from "./Components/Loader";


const Routes = () => {

    let lat = localStorage.getItem("Lat");
    let lng = localStorage.getItem("Lng");
    const dispatch = useDispatch();
    const settings = useSelector((state) => state.Settings)?.settings;
    const authentication = useSelector((state) => state.authentication)?.isLoggedIn;

    useEffect(() => {
        if (settings.length === 0) {
            api.get_settings().then((setting) => {
                dispatch(setSettings(setting.data));
                localStorage.setItem(
                    "Company",
                    setting.data.general_settings.company_title
                );
                localStorage.setItem(
                    "currencySymbol",
                    setting.data.general_settings.currency
                );
                localStorage.setItem(
                    "currencyCode",
                    setting.data.general_settings.country_currency_code
                );
                localStorage.setItem(
                    "DoorstepAvailable",
                    setting.data.general_settings.at_doorstep
                );
                localStorage.setItem(
                    "StoreAvailable",
                    setting.data.general_settings.at_store
                );
            });
        } else {
            localStorage.setItem(
                "Company",
                settings.general_settings.company_title
            );
            localStorage.setItem(
                "currencySymbol",
                settings.general_settings.currency
            );
            localStorage.setItem(
                "currencyCode",
                settings.general_settings.country_currency_code
            );
            localStorage.setItem(
                "DoorstepAvailable",
                settings.general_settings.at_doorstep
            );
            localStorage.setItem(
                "StoreAvailable",
                settings.general_settings.at_store
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
        if (authentication === true) {
            var formdata = new FormData();
            formdata.append("type", "list");
            formdata.append("latitude", lat);
            formdata.append("longitude", lng);

            api.get_bookmarks(formdata).then((bookmarks) => {
                if (bookmarks.error === false) {
                    let data = bookmarks.data;
                    const markedArray = data.map((item, index) => ({
                        id: index + 1,
                        partner_id: item.partner_id
                    }))
                    dispatch(setBookmark(markedArray));
                }
            });

            api
                .get_cart_plain()
                .then((response) => {
                    dispatch(updateCartItems(response.data?.data));
                    dispatch(updateBaseCart(response.data));
                    localStorage.setItem('cartItems', JSON.stringify(response.data));
                    // let sub_amout = 0;
                    localStorage.setItem("sub_amout", parseFloat(response.data.sub_total));
                })

            api
                .getAddress()
                .then((response) => {
                    dispatch(setAddress(response.data));
                })
                .catch((error) => console.log("error", error));

        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);



    if (!localStorage.getItem("isLoggedIn")) {
        // Set the variable to an empty value if it doesn't exist
        localStorage.setItem("isLoggedIn", false);
    }
    if (!localStorage.getItem("ContactInfo")) {
        // Set the variable to an empty value if it doesn't exist
        localStorage.setItem("ContactInfo", "");
    }
    if (!localStorage.getItem("ProfilePicture")) {
        // Set the variable to an empty value if it doesn't exist
        localStorage.setItem("ProfilePicture", "");
    }
    if (!localStorage.getItem("language")) {
        // Set the variable to an empty value if it doesn't exist
        localStorage.setItem("language", 10);
    }
    if (!localStorage.getItem("selectedPromo")) {
        // Set the variable to an empty value if it doesn't exist
        localStorage.setItem("selectedPromo", "");
    }
    if (!localStorage.getItem("selectedAddres")) {
        // Set the variable to an empty value if it doesn't exist
        localStorage.setItem("selectedAddres", "");
    }

    return (
        <Suspense fallback={<Loader />}>
            <ScrollToTop />
            <Router />
        </Suspense>
    );
};

export default Routes;




