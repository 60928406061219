import { Suspense  } from 'react'
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux'
import { store } from './redux/store'

import App from './App';
import "./i18n";
import { BrowserRouter } from 'react-router-dom';
import Loader from './Components/Loader';
import "./CSS/bootstrap.min.css"
import "./CSS/style.css";
import "./CSS/darkmode.css"

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <>
    <Provider store={store}>
    <Suspense fallback={<Loader />}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Suspense>
    </Provider>
  </>
);
